import {
    Modal,
  } from "@carbon/react";

interface WarningMessageProps {
    showDialog: boolean
    cancelNavigation: any
    confirmNavigation: any
}

const WarningMessage: React.FC<WarningMessageProps> = ({
    showDialog,
    cancelNavigation,
    confirmNavigation,
}) => {
    return (
        <Modal
            open={showDialog}
            modalHeading="Sie haben ungespeicherte Änderungen im Formular. Wollen sie die Seite wirklich verlassen?"
            modalLabel="Ungespeicherte Änderungen"
            primaryButtonText="Ja"
            secondaryButtonText="Nein"
            onRequestSubmit={confirmNavigation}
            onSecondarySubmit={cancelNavigation}
            onRequestClose={cancelNavigation}
            >
        </Modal>
    )

    //  <Modal show={showDialog}>
    //  <Modal.Header>
    //    <Modal.Title>Warning</Modal.Title>
    //  </Modal.Header>
    //  <Modal.Body>
    //    <b>There are some changes?</b>
    //    <br /> Are you sure you want to navigate!!!!
    //  </Modal.Body>
    //  <Modal.Footer>
    //    <Button variant="primary" onClick={cancelNavigation}>
    //      No
    //    </Button>
    //    <Button variant="danger" onClick={confirmNavigation}>
    //      Yes
    //    </Button>
    //  </Modal.Footer>
    //</Modal>
}
export default WarningMessage