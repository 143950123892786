import React from "react";
import { TextInput } from "@carbon/react";
import "./Form.scss";
import { useFormState } from "../../atoms/useFormState";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";

export default function AddressForm() {
  const [showInvalidFieldsStartBot, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();
  const [formState, setFormState] = useFormState();
  const addressFormState = formState.personalInformation.address;

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      personalInformation: {
        ...formState.personalInformation,
        address: { ...formState.personalInformation.address, [propertyName]: propertyValue },
      },
    });
  };

  return (
    <div className="w-full pl-8 pr-8 mt-4">
      <div className="grid grid-cols-2 gap-4 w-full">
        <TextInput
          id="streetAndHouse"
          type="text"
          labelText="Straße Hausnummer*"
          size="sm"
          onChange={handleChange}
          defaultValue={addressFormState.streetAndHouse}
          invalid={!addressFormState.streetAndHouse && showInvalidFieldsStartBot}
          invalidText="Bitte geben Sie Straße und Hausnummer ein."
        />

        <TextInput
          id="city"
          type="text"
          labelText="Ort*"
          size="sm"
          onChange={handleChange}
          defaultValue={addressFormState.city}
          invalid={!addressFormState.city && showInvalidFieldsStartBot}
          invalidText="Bitte geben Sie Ort ein."
        />

        <TextInput
          id="postIndex"
          type="text"
          labelText="PLZ*"
          size="sm"
          onChange={handleChange}
          defaultValue={addressFormState.postIndex}
          invalid={!addressFormState.postIndex && showInvalidFieldsStartBot}
          invalidText="Bitte geben Sie PLZ ein."
        />

        <TextInput
          id="additionalAddressInformation"
          type="text"
          labelText="Adresszusatz"
          size="sm"
          onChange={handleChange}
          defaultValue={addressFormState.additionalAddressInformation}
        />
      </div>
    </div>
  );
}
