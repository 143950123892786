import React, { useEffect, useState } from "react";
import { TextInput, RadioButtonGroup, DatePickerInput, DatePicker } from "@carbon/react";
import { generateRadioButtons } from "../../utils/elementGenerator";
import { HEALTH_INSURANCE_STATUS_VALUES } from "../../types/FormData";
import { useFormState } from "../../atoms/useFormState";
import { format } from "date-fns";
import {
  healthInsuranceSinceIsValid,
  pensionFundAccountNumberIsValid,
} from "../../utils/validator";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";

export default function SocialInsuranceDataForm() {
  const [showInvalidFieldsStartBot, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();
  const [formState, setFormState] = useFormState();
  const socialInsuranceDataFormState = formState.personalInformation.socialInsuranceData;
  const [healthInsuranceStatusSinceIsDisabled, setHealthInsuranceStatusSinceIsDisabled] =
    useState<boolean>(true);

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const handleDateChange = (e, key) => {
    changeFormStateProperty(key, format(e[0], "dd.MM.yyyy"));
  };

  const handleOtherChanges = (e, key) => {
    changeFormStateProperty(key, e);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      personalInformation: {
        ...formState.personalInformation,
        socialInsuranceData: {
          ...formState.personalInformation.socialInsuranceData,
          [propertyName]: propertyValue,
        },
      },
    });
  };

  // Resetting Form Values:
  // Wrapped in useEffect to avoid update errors from parent component
  useEffect(() => {
    setHealthInsuranceStatusSinceIsDisabled(getHealthInsuranceStatusSinceIsDisabled());
  }, [socialInsuranceDataFormState]);

  const getHealthInsuranceStatusSinceIsDisabled = (): boolean => {
    const activationValues = ["Freiwillig", "Privat"];
    if (!activationValues.every((i) => HEALTH_INSURANCE_STATUS_VALUES.includes(i))) {
      throw new TypeError("HEALTH_INSURANCE_STATUS_VALUES don't match business logic");
    }

    if (
      !socialInsuranceDataFormState.healthInsuranceStatus ||
      !activationValues.includes(socialInsuranceDataFormState.healthInsuranceStatus)
    ) {
      resetProperty("healthInsuranceSince");
      resetProperty("lastStateInsuranceCompany");
      return true;
    }
    return false;
  };

  const resetProperty = (property: string) => {
    switch (typeof socialInsuranceDataFormState[property]) {
      case "boolean": {
        if (socialInsuranceDataFormState[property] !== false) {
          changeFormStateProperty(property, false);
        }
        break;
      }
      case "number": {
        if (socialInsuranceDataFormState[property] !== 0) {
          changeFormStateProperty(property, 0);
        }
        break;
      }
      default: {
        if (socialInsuranceDataFormState[property] !== "") {
          changeFormStateProperty(property, "");
        }
        break;
      }
    }
  };

  return (
    <div className="pl-8 pr-8 grid grid-cols-1 gap-4 w-full mt-4">
      <TextInput
        id="healthInsuranceCompany"
        type="text"
        labelText="Krankenkasse*"
        size="sm"
        onChange={handleChange}
        defaultValue={socialInsuranceDataFormState.healthInsuranceCompany}
        invalid={!socialInsuranceDataFormState.healthInsuranceCompany && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Krankenkasse ein."
      />

      <TextInput
        id="pensionFundAccountNumber"
        type="text"
        labelText="Rentenversicherungsnummer*"
        size="sm"
        onChange={handleChange}
        defaultValue={socialInsuranceDataFormState.pensionFundAccountNumber}
        invalid={!pensionFundAccountNumberIsValid(formState, showInvalidFieldsStartBot)}
        invalidText="Bitte geben Sie Rentenversicherungsnummer ein."
      />

      <RadioButtonGroup
        className={
          showInvalidFieldsStartBot && !socialInsuranceDataFormState.healthInsuranceStatus
            ? `border-2 border-solid border-red-600`
            : ""
        }
        legendText="Status Krankenversicherung*"
        name="healthInsuranceStatus"
        onChange={(e) => handleOtherChanges(e, "healthInsuranceStatus")}
        defaultSelected={socialInsuranceDataFormState.healthInsuranceStatus}
      >
        {generateRadioButtons(HEALTH_INSURANCE_STATUS_VALUES)}
      </RadioButtonGroup>

      <div className="grid grid-cols-2 gap-4">
        <DatePicker
          datePickerType="single"
          dateFormat="d.m.Y"
          onChange={(e) => handleDateChange(e, "healthInsuranceSince")}
        >
          <DatePickerInput
            placeholder="tt.mm.jjjj"
            labelText="seit*"
            id="healthInsuranceSince"
            size="sm"
            onChange={handleChange}
            defaultValue={socialInsuranceDataFormState.healthInsuranceSince}
            disabled={healthInsuranceStatusSinceIsDisabled}
            invalid={
              !healthInsuranceSinceIsValid(
                formState,
                showInvalidFieldsStartBot,
                healthInsuranceStatusSinceIsDisabled
              )
            }
            invalidText="Bitte geben Sie das Datum im validen Format ein."
          />
        </DatePicker>

        <TextInput
          id="lastStateInsuranceCompany"
          type="text"
          labelText="Letzte Pflichtkasse"
          size="sm"
          onChange={handleChange}
          defaultValue={socialInsuranceDataFormState.lastStateInsuranceCompany}
          disabled={healthInsuranceStatusSinceIsDisabled}
        />
      </div>
    </div>
  );
}
