import { Checkmark, Time } from "@carbon/icons-react";
import { useUser } from "../../atoms/useUser";

export interface ChatMessageProps {
  message: string;
  sender: string;
  timestamp: string;
  read: boolean;
}

export default function ChatMessage({ sender, message, timestamp, read }: ChatMessageProps) {
  const [user, _] = useUser();
  const senderType = sender === user ? "user" : "other";

  const formattedTimestamp = new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      className={
        senderType === "user" ? "flex justify-end my-2 ml-8" : "flex justify-start my-2 mr-8"
      }
    >
      <div
        className={
          senderType === "user" ? "bg-green-200 rounded-lg p-2" : "bg-gray-200 rounded-lg p-2"
        }
      >
        <p className="text-sm text-gray-700">{message}</p>

        <div
          className={`flex flex-row mt-1 ${
            senderType === "user" ? "justify-end" : "justify-start"
          }`}
        >
          <p className="text-xs text-gray-500 flex items-center flex-row">
            {senderType === "user" ? "Du" : sender} - {formattedTimestamp}
            {senderType === "user" &&
              (read ? (
                <Checkmark className="fill-blue-600 h-[90%]" />
              ) : (
                <Time className="fill-gray-400 h-[80%] ml-1" />
              ))}
          </p>
        </div>
      </div>
    </div>
  );
}
