
export const downloadDocument = async (employee_id: string, attachment_name: string) => {
    
    const response = await fetch(`/api/documents/${employee_id}/${attachment_name}`)

    // download the file
    const blob = await response.blob()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attachment_name)
    link.click()

}