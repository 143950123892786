interface ModalProps {
  children: React.ReactNode;
  show: boolean;
}

export default function Modal({ children, show }: ModalProps) {
  return (
    <div className={show ? "" : "hidden"}>
      <div className="absolute bg-modal-bg top-0 left-0 z-[8001] w-full h-full flex justify-center items-center">
        {children}
      </div>
    </div>
  );
}
