import React from "react";
import botRecording from "../../assets/hoop_create_sap_user_anonym.mp4";

export default function BotVideo() {
  return (
    <video controls>
      <source src={botRecording} type="video/mp4" />
    </video>
  );
}
