import {
  Add,
  Checkmark,
  CheckmarkFilled,
  CheckmarkOutline,
  CircleFilled,
  Edit,
  ErrorOutline,
  LicenseThirdPartyDraft,
  PendingFilled,
  ProgressBarRound,
  SortDescending,
  TrashCan,
  Warning,
} from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  DataTableSkeleton,
} from "@carbon/react";
import { useState } from "react";
import { useUser } from "../../atoms/useUser";
import { EmployeeData } from "../../types/OnboardingProcess";
import { getNumberOfUnreadChatMessages } from "../../utils/getNumberOfUnreadChatMessages";
import { statusTranslations } from "../../utils/statusTranslations";
import NotificationBubble from "../Chat/NotificationBubble";

export type OnboardingProcessRow = {
  name: string;
  id: string;
  hire_date: string;
  status: string;
};

export interface OnboardingProcessesTableProps {
  onboardingProcesses: EmployeeData[];
  isLoading?: boolean;
  onClickAdd?: () => void;
  onClickRow?: (id: string) => void;
  headers: { header: string; key: string }[];
  filters?: string[];
  title?: any;
}

const renderIcon = (status: string) => {
  status ? (status = status) : (status = "");

  switch (status) {
    case "Überprüfung":
      return <PendingFilled className="fill-primary mr-1" />;
    case "unvollständig":
      return <Warning className="fill-hoop-yellow mr-1" />;
    case "Entwurf":
      return <Edit className="fill-hoop-blue-400 mr-1" />;
    case "abgeschlossen":
      return <CheckmarkOutline className="fill-green-500 mr-1" />;

    // Bot States
    case "transferring":
      return (
        <div className="group relative">
          <CircleFilled className="fill-yellow-500 mr-1" />
          <span className="absolute hidden bg-black text-white text-sm rounded-md group-hover:block p-1 whitespace-nowrap right-0 top-0">
            {statusTranslations[status]}
          </span>
        </div>
      );
    case "inQueue":
      return (
        <div className="group relative">
          <CircleFilled className="fill-yellow-500 mr-1" />
          <span className="absolute hidden bg-black text-white text-sm rounded-md group-hover:block p-1 whitespace-nowrap right-0 top-0">
            {statusTranslations[status]}
          </span>
        </div>
      );
    case "success":
      return (
        <div className="group relative">
          <CircleFilled className="fill-green-500 mr-1" />
          <span className="absolute hidden bg-black text-white text-sm rounded-md group-hover:block p-1 whitespace-nowrap right-0 top-0">
            {statusTranslations[status]}
          </span>
        </div>
      );
    case "error":
      return (
        <div className="group relative">
          <CircleFilled className="fill-red-500 mr-1" />
          <span className="absolute hidden bg-black text-white text-sm rounded-md group-hover:block p-1 whitespace-nowrap right-0 top-0">
            {statusTranslations[status]}
          </span>
        </div>
      );
    case "":
      return (
        <div className="group relative">
          <CircleFilled className="fill-gray-300 mr-1" />
          <span className="absolute hidden bg-black text-white text-sm rounded-md group-hover:block p-1 whitespace-nowrap right-0 top-0">
            Noch nicht gestartet
          </span>
        </div>
      );

    default:
      return null;
  }
};

export default function OnboardingProcessesTable({
  onboardingProcesses,
  isLoading,
  onClickAdd,
  onClickRow,
  headers,
  filters,
  title,
}: OnboardingProcessesTableProps) {
  const [filter, setFilter] = useState("");
  const [user] = useUser();
  const cellForNotificationBubble = user.toLowerCase().includes("barbara") ? "botStatus" : "status";

  onboardingProcesses.forEach((process) => {
    // status mapping to show less status for customer table
    process.status = statusTranslations[process.status]
      ? statusTranslations[process.status]
      : process.status;
  });

  const filteredProcesses = onboardingProcesses.filter((process) => {
    //filter process by every key and by filterArray
    let keep = false;
    const totalFilters = filters ? [filter, ...filters] : [filter];
    for (let filter of totalFilters) {
      let filterMatch = false;
      const filterOptions = filter.split("|").map((option) => option.trim().toLowerCase());
      for (let key in process) {
        process[key] ? (process[key] = process[key]) : (process[key] = "");
        const propertyValue = process[key].toString().toLowerCase();
        // startsWith could also be replaced with includes
        if (filterOptions.some((option) => propertyValue.toLowerCase().startsWith(option))) {
          filterMatch = true;
          keep = true;
          break;
        }
      }
      if (!filterMatch) {
        return false;
      }
    }
    return keep;
  });

  const rows = filteredProcesses.map((employee) => {
    return {
      id: employee.id,
      employeeNumber: employee.employeeNumber,
      lastName: employee.lastName,
      firstName: employee.firstName,
      hireDate: employee.hireDate.toString(),
      employer: employee.employer,
      status: employee.status,
      botStatus: employee.botStatus,
    };
  });

  // CHeck if click on row is an actual click
  const [isClick, setIsClick] = useState(false);
  const [startClickTime, setStartClickTime] = useState<number>(0);

  function handleMouseDown() {
    setStartClickTime(Date.now());
  }

  function handleMouseUp() {
    const endClickTime = Date.now();
    const clickDuration = endClickTime - startClickTime;
    if (clickDuration < 500) {
      setIsClick(true);
    } else {
      setIsClick(false);
    }
  }

  function handleClick(id: string) {
    if (isClick && onClickRow) {
      onClickRow(id);
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="max-w-[960px]">
          <DataTableSkeleton
            compact={true}
            showHeader={false}
            headers={headers}
            rowCount={5}
            columnCount={headers.length}
          />
        </div>
      ) : (
        <>
          <DataTable rows={rows} headers={headers} isSortable className="overflow-visible">
            {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
              <TableContainer className="table-top overflow-visible" title={title} description="">
                <TableToolbar aria-label="data table toolbar">
                  <TableToolbarContent>
                    <TableToolbarSearch onChange={(e) => setFilter(e.target.value)} />
                    <Button
                      hasIconOnly
                      iconDescription="Löschen"
                      kind="ghost"
                      renderIcon={TrashCan}
                    ></Button>
                    <Button
                      hasIconOnly
                      iconDescription="Sortieren"
                      kind="ghost"
                      renderIcon={SortDescending}
                    ></Button>
                    {onClickAdd && (
                      <Button
                        renderIcon={Add}
                        className="bg-primary hover:bg-hoop-blue-400"
                        onClick={onClickAdd}
                      >
                        Hinzufügen
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>

                <Table {...getTableProps()} className="overflow-visible">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        {...getRowProps({ row })}
                        className={
                          row.cells[0].value.includes("Verarbeitung")
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onClick={() => {
                          if (row.cells[0].value.includes("Verarbeitung")) return;
                          handleClick(row.id);
                        }}
                      >
                        <>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                              <div className="flex relative items-center gap-2">
                                {renderIcon(cell.value)}
                                {/* dont display the text in botStatus cell: */}
                                {cell.info.header !== "botStatus" && cell.value}{" "}
                                {/* Display Bubble only, when messages > 0 and only in botStatus Cell */}
                                {getNumberOfUnreadChatMessages(
                                  row.id,
                                  onboardingProcesses,
                                  user
                                ) !== 0 &&
                                  cell.info.header === cellForNotificationBubble && (
                                    <NotificationBubble
                                      text={getNumberOfUnreadChatMessages(
                                        row.id,
                                        onboardingProcesses,
                                        user
                                      )}
                                    />
                                  )}
                              </div>
                            </TableCell>
                          ))}
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DataTable>
          <Pagination
            backwardText="Previous page"
            forwardText="Next page"
            itemsPerPageText=""
            onChange={function noRefCheck() {}} //what is it?
            page={1}
            pageSize={10}
            pageSizes={[10, 20, 30, 40, 50]} //find a smarter way to represent this
            size="sm"
            totalItems={103} //get from API
          />
        </>
      )}
    </div>
  );
}
