import React from "react";
import { TextInput, SelectItem, Select } from "@carbon/react";
import { generateSelectItems } from "../../utils/elementGenerator";
import { TYPE_OF_INVESTMENT_VALUES } from "../../types/FormData";
import { useFormState } from "../../atoms/useFormState";
import { typeOfInvestmentIsValid } from "../../utils/validator";

export default function CapitalFormationForm() {
  const [formState, setFormState] = useFormState();
  const capitalFormationData = formState.organisationalInformation.capitalFormationData;

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      organisationalInformation: {
        ...formState.organisationalInformation,
        capitalFormationData: {
          ...formState.organisationalInformation.capitalFormationData,
          [propertyName]: propertyValue,
        },
      },
    });
  };
  return (
    <div className="grid grid-cols-2 gap-4 w-full mt-4 pl-8 pr-8 pb-4">
      <TextInput
        name="vlAgShare"
        id="vlAgShare"
        type="text"
        labelText="VL AG-Anteil (EUR)"
        size="sm"
        onChange={handleChange}
        defaultValue={capitalFormationData.vlAgShare}
      />

      <TextInput
        name="vlPayment"
        id="vlPayment"
        type="text"
        labelText="VL Abführungsbetrag"
        size="sm"
        onChange={handleChange}
        defaultValue={capitalFormationData.vlPayment}
      />

      {/* TODO: in design it should be RadioButton component, but it doesn't support combination of vertical and horisontal spans. */}
      <Select
        id="typeOfInvestment"
        labelText="Anlageart"
        defaultValue={capitalFormationData.typeOfInvestment}
        size="sm"
        onChange={handleChange}
        invalid={!typeOfInvestmentIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(TYPE_OF_INVESTMENT_VALUES)}
      </Select>

      <TextInput
        name="vlContractNumber"
        id="vlContractNumber"
        type="text"
        labelText="VL Vertragsnummer"
        size="sm"
        onChange={handleChange}
        defaultValue={capitalFormationData.vlContractNumber}
      />

      <TextInput
        name="receiver"
        id="receiver"
        type="text"
        labelText="Empfänger"
        size="sm"
        onChange={handleChange}
        defaultValue={capitalFormationData.receiver}
      />

      <TextInput
        name="iban"
        id="iban"
        type="text"
        labelText="IBAN"
        size="sm"
        onChange={handleChange}
        defaultValue={capitalFormationData.iban}
      />
    </div>
  );
}
