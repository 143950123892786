export const addQueueItem = async (queueName: string, employee_id: string) => {
    
    const requestOptions = {
      method: "POST",
    };

    const response = await fetch(`/api/queue/${queueName}?employee_id=${employee_id}`, requestOptions);
    const data = await response.json();
    return data;
  };
  