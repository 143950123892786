import { atom, useRecoilState } from "recoil";
import { FormType } from "../types/FormData";

export const defaultFormState: FormType = {
  personalInformation: {
    basicPersonalInformation: {
      salutation: "",
      title: "",
      firstName: "",
      lastName: "",
      birthName: "",
      birthday: "",
      placeOfBirth: "",
      nationality: "",
      sex: "",
      iban: "",
      familyStatus: "",
      familyStatusSince: "",
      secondaryEducation: "",
      professionalEducation: "",
      disability: false,
    },
    address: {
      streetAndHouse: "",
      city: "",
      postIndex: "",
      additionalAddressInformation: "",
    },
    taxData: {
      taxId: "",
      taxClass: "",
      taxClassFactor: "",
      churchTax: false,
      confession: "",
      children: false,
      childAllowance: false,
      childAllowanceFactor: "",
    },
    socialInsuranceData: {
      healthInsuranceCompany: "",
      pensionFundAccountNumber: "",
      healthInsuranceStatus: "",
      healthInsuranceSince: "",
      lastStateInsuranceCompany: "",
    },
  },
  organisationalInformation: {
    organisationSpecificInformation: {
      personalNumber: "",
      personalPartition: "",
      startDate: "",
      positionNumber: "",
      newInConcern: false,
      dateConcernEntered: "",
      costCenter: "",
      personGroup: "",
      employeeGroup: "",
      exitLimitation: "",
      employeeCircle: "",
      workingHoursWeekly: 0,
      endOfEducation: "",
      transitionField: false,
      shortTimeEmployment: false,
      positionKey: "",
      otherPositionKey: "",
      minorEmployment: false,
      withRetirementInsurance: false,
      multiEmployment: false,
      workingHoursPattern: "",
      email: "",
      telephone: "",
      userId: "",
    },
    salaryData: {
      group: "",
      level: "",
      allowance1: "",
      allowanceAmount1: "",
      allowance2: "",
      allowanceAmount2: "",
      hourlySalary: 0,
      flatTaxGfbKfb: false,
      flatTaxEmployerCoverage: false,
    },
    capitalFormationData: {
      vlAgShare: "",
      vlPayment: "",
      typeOfInvestment: "",
      vlContractNumber: "",
      receiver: "",
      iban: "",
    },
  },
};

const formStateAtom = atom<FormType>({
  key: "formState",
  default: defaultFormState,
});

export const useFormState = () => useRecoilState(formStateAtom);
