import { Switcher, Notification, UserAvatar, BrightnessContrast } from '@carbon/react/icons';
import { Link } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from '@carbon/react';
import { MvpRoute } from '../../types/MvpRoute';
import './MvpHeader.scss';
import { Person } from '@carbon/icons-react';

interface MvpHeaderProps {
  routes: MvpRoute[];
  prefix: string;
  title: string;
  activePage: number;
  setActivePage: CallableFunction;
}

const SWITCH_THEMES = ['white', 'g90'];

const MvpHeader = ({
  routes,
  prefix,
  title,
  activePage,
  setActivePage
}: MvpHeaderProps) => (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }: any) => (
      <Header aria-label="MVP">
        <SkipToContent />
        <HeaderMenuButton
          className="header_button"
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <Person size={20} className={"fill-white w-5 ml-4"}/>
        <HeaderName element={Link} to="/" prefix={prefix}>
          {title}
        </HeaderName>
        <HeaderNavigation aria-label="">
          {routes.map((route: MvpRoute, index: number) => {
            return (
              !route.isStartPage && <HeaderMenuItem
                      isCurrentPage={route.id === activePage}
                      element={Link}
                      to={route.path}
                      key={index}
                      onClick={() => {
                        setActivePage(route.id);
                      }}
                    >
                      {route.label}
                    </HeaderMenuItem>

            );
          })}
        </HeaderNavigation>
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
          <SideNavItems>
            <HeaderSideNavItems>
              {routes.map((route: MvpRoute, index: number) => {
                return (
                        !route.isStartPage && route.label && <HeaderMenuItem
                          element={Link}
                          to={route.path}
                          key={index}
                          onClick={() => {
                            setActivePage(route.id);
                          }}
                        >
                          {route.label}
                        </HeaderMenuItem>
                );
              })}
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
        <HeaderGlobalBar>
          {/* <HeaderGlobalAction aria-label="Theme Switcher" tooltipAlignment="start">
            <BrightnessContrast
              size={20}
              onClick={() =>
                setCurrentTheme((oldTheme: string) =>
                  oldTheme === SWITCH_THEMES[0] ? SWITCH_THEMES[1] : SWITCH_THEMES[0],
                )
              }
            />
          </HeaderGlobalAction> */}
          <HeaderGlobalAction aria-label="Notifications" tooltipAlignment="center">
            <Notification size={20} className="fill-white"/>
          </HeaderGlobalAction>
          <HeaderGlobalAction aria-label="User Avatar" tooltipAlignment="center">
            <UserAvatar size={20} />
          </HeaderGlobalAction>
          <HeaderGlobalAction aria-label="App Switcher" tooltipAlignment="end">
            <Switcher size={20} />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
      </Header>
    )}
  />
);

export default MvpHeader;
