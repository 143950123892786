export const deleteEmployee = async (id: string) => {
  //turn employeedata into a string
  
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`/api/employees/${id}`, requestOptions);
  const data = await response.json();
  return data;
};
