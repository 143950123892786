import { CloseOutline, Send } from "@carbon/icons-react";
import { TextInput, IconButton } from "@carbon/react";
import { useEffect, useState } from "react";
import { useUser } from "../../atoms/useUser";
import Modal from "../Modal/Modal";
import ChatMessage, { ChatMessageProps } from "./ChatMessage";
import { MessageHandler } from "./MessageHandler";

interface ChatProps {
  show: boolean;
  onClose: () => void;
  onShow?: () => void;
  userId: string;
  onUpdateChatMessages?: (chatMessages: ChatMessageProps[]) => void;
}

export default function Chat({ show, onClose, userId, onShow, onUpdateChatMessages }: ChatProps) {
  const [chatMessages, setChatMessages] = useState<ChatMessageProps[]>([]);
  const [user] = useUser();
  const [chatInput, setChatInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const sortedChatMessages = chatMessages.sort((a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  });
  const [messageHandler] = useState<MessageHandler>(
    new MessageHandler(
      userId,
      user,
      (messages) => customSetChatMessages(messages),
      () => console.log("new Message")
    )
  );

  const customSetChatMessages = (messages: ChatMessageProps[]) => {
    // set local state
    setChatMessages(messages);
    if (onUpdateChatMessages) {
      // run function from parent component
      onUpdateChatMessages(messages);
    }
  };

  useEffect(() => {
    messageHandler.pollChatMessages();
    return () => {
      messageHandler.cancel();
      messageHandler.setChatOpen(false);
    };
  }, [userId]);

  useEffect(() => {
    if (show) {
      if (onShow) {
        onShow();
      }
      messageHandler.setChatOpen(true);
    } else {
      messageHandler.setChatOpen(false);
    }
  }, [show]);

  const handleChatInput = (e) => {
    setChatInput(e.target.value);
  };

  const addMessage = async () => {
    setLoading(true);
    setChatInput("");
    await messageHandler.sendMessage(chatInput);
    setLoading(false);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (loading || chatInput === "") return;
      addMessage();
    }
  };

  return (
    <Modal show={show && userId !== "new"}>
      <div className="bg-white w-[360px] h-[600px] rounded relative overflow-hidden flex flex-col">
        <div className="bg-gray-50 w-full h-8 flex-shrink-0">
          <CloseOutline className="absolute top-2 right-2 cursor-pointer" onClick={onClose} />
        </div>
        {/* Message Area: */}
        <div className="flex flex-col-reverse flex-grow px-2 overflow-y-scroll">
          {sortedChatMessages.map((message, index) => {
            return <ChatMessage {...message} key={index} />;
          })}
        </div>
        {/* Input Area: */}
        <div className="bg-gray-50 flex flex-row">
          <TextInput
            id="chat-input"
            labelText=""
            placeholder="Schreibe eine Nachricht..."
            type="text"
            className=""
            value={chatInput}
            onChange={handleChatInput}
            onKeyPress={onKeyPress}
          />
          <IconButton className="bg-primary" label="Send" size="md" onClick={() => onKeyPress({key: "Enter"})}>
            <Send />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}
