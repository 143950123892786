export const statusTranslations = {
    'processing': 'Verarbeitung',
    'checking': 'Überprüfung',
    'incomplete': 'unvollständig',
    'complete': 'vollständig',
    'transferring': 'Übertragung',
    'error': 'Fehler',
    'finished': 'abgeschlossen',
    'inQueue': 'in Warteschlange',
    'success': 'Erfolg',
    'draft': 'Entwurf',
}