import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MvpRoute } from '../../types/MvpRoute';
import MvpHeader from '../MvpHeader/MvpHeader';
import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
  mvpRoutes: MvpRoute[];
  appPrefix: string;
  appTitle: string;
  activePage: number;
  setActivePage: CallableFunction;
}

export default function Layout({
  children,
  mvpRoutes,
  appPrefix,
  appTitle,
  activePage,
  setActivePage
}: LayoutProps) {

  const location = useLocation();

  // separate Admin and Customer routes
  const adminRoutes = mvpRoutes.filter((route) => route.path.startsWith('/admin'));
  const customerRoutes = mvpRoutes.filter((route) => !route.path.startsWith('/admin'));

  return (
    location.pathname.startsWith("/admin") ? (
    <div className="viewport">
      <MvpHeader
        routes={adminRoutes}
        prefix={appPrefix}
        title={appTitle}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <div className="bodyWrapper">{children}</div>
    </div>
    ) : (
    <div className="viewport">
      <MvpHeader
        routes={customerRoutes}
        prefix={appPrefix}
        title={appTitle}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <div className="bodyWrapper">{children}</div>
    </div>
    )
  );
}
