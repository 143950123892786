import { CloseOutline } from "@carbon/icons-react";
import { useState } from "react";
import Card from "../../components/Card/Card";
import DocImage from "../../assets/doc.svg";
import MonitorImage from "../../assets/illustration_monitor.svg";
import Modal from "../../components/Modal/Modal";
import OnboardingProcessesTable from "../../components/OnboardingProcessTable/OnboardingProcessesTable";
import { useNavigate } from "react-router-dom";
import { useOnboardingProcesses } from "../../hooks/useOnboardingProcesses";

export default function CustomerDashboard() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { isLoading, onboardingProcesses } = useOnboardingProcesses();

  const tableHeaders = [
    { header: "Vorname", key: "firstName" },
    { header: "Nachname", key: "lastName" },
    { header: "Personalnummer", key: "employeeNumber" },
    { header: "Eintrittsdatum", key: "hireDate" },
    { header: "Status", key: "status" },
  ];

  return (
    <>
      <Modal show={showModal}>
        <div className="flex h-[588px] w-[640px] bg-white relative rounded-[3px]">
          <CloseOutline
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
          <div className="w-full mx-20 my-12">
            <h2 className="text-xl mb-4">Mitarbeiter:in hinzufügen</h2>
            <p className="text-sm mb-4 w-[380px]">
              Damit das Onboarding reibunslos funktioniert, benötigen wir ein paar Informationen von
              Ihnen.
            </p>
            <p className="text-sm w-[380px] mb-10">
              Bitte wählen Sie zwischen dem Upload eines ausgefüllten Stammdatenblatts in PDF
              Format, oder liefern Sie ihre Daten über eine Eingabemaske an.{" "}
            </p>

            <div className="w-full flex flex-row justify-between">
              <Card
                title="Stammdatenblatt hochladen"
                imageSrc={DocImage}
                onClick={() => {
                  navigate("/processes/upload");
                }}
              />
              <Card
                title="Eingabemaske ausfüllen"
                imageSrc={MonitorImage}
                onClick={() => {
                  navigate("/processes/new");
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex justify-center">
        <div className="w-[928px] mb-32">
          <h2 className="mt-20 text-xl">Übersicht Onboarding Vorgänge</h2>
          <p className="text-secondary text-sm mt-1">
            Erhalten Sie hier eine Übersicht aller Onboarding Vorgänge, erstellen Sie neue,
          </p>
          <p className="text-secondary text-sm mb-8">oder bearbeiten Sie bestehende</p>
          <OnboardingProcessesTable
            isLoading={isLoading}
            onboardingProcesses={onboardingProcesses}
            headers={tableHeaders}
            onClickAdd={() => setShowModal(true)}
            onClickRow={(id) => navigate(`/processes/${id}`)}
          />
        </div>
      </div>
    </>
  );
}
