import { useUser } from "../atoms/useUser";
import { ChatMessageProps } from "../components/Chat/ChatMessage";
import { EmployeeData } from "../types/OnboardingProcess";

export const getNumberOfUnreadChatMessages = (
  employee_id: string,
  onboardingProcesses: EmployeeData[],
  user: string
) => {
  const employee = onboardingProcesses.find((employee) => employee.id === employee_id);
  if (!employee) return 0;
  const { chat_messages } = employee;
  const unreadMessages = chat_messages!.filter(
    (message: ChatMessageProps) => message.sender !== user && !message.read
  );
  return unreadMessages.length;
};
