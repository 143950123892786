import { CONFESSION_VALUES, EMPLOYEE_CIRCLE_VALUES, EMPLOYEE_GROUP_VALUES, FAMILY_STATUS_VALUES, FormType, NATIONALITY_VALUES, PERSON_GROUP_VALUES, POSITION_KEY_VALUES, PROFESSIONAL_EDUCATION_VALUES, SALUTATION_VALUES, SECONDARY_EDUCATION_VALUES, TAX_CLASS_VALUES, TITLE_VALUES, TYPE_OF_INVESTMENT_VALUES, WORKING_HOURS_PATTERN_VALUES } from "../types/FormData";

export const validToSaveData = (formData: FormType) => {
  return (
    compulsoryFieldsArePresentToSaveData(formData) && valuesAreValid(formData)
  );
};

export const validToStartBot = (formData: FormType) => {
  return compulsoryFieldsArePresentToStartBot(formData) && valuesAreValid(formData);
};

const compulsoryFieldsArePresentToSaveData = (formData: FormType) => {
  const organisationSpecificInformation =
    formData.organisationalInformation.organisationSpecificInformation;
  const basicPersonalInformation = formData.personalInformation.basicPersonalInformation;
  return (
    organisationSpecificInformation.personalNumber &&
    basicPersonalInformation.firstName &&
    basicPersonalInformation.lastName &&
    basicPersonalInformation.sex &&
    organisationSpecificInformation.startDate
  );
};

const compulsoryFieldsArePresentToStartBot = (formData: FormType) => {
  const basicPersonalInformation = formData.personalInformation.basicPersonalInformation;
  const address = formData.personalInformation.address;
  const taxData = formData.personalInformation.taxData;
  const socialInsurance = formData.personalInformation.socialInsuranceData;
  const organisationSpecificInformation =
    formData.organisationalInformation.organisationSpecificInformation;

  return (
    //*** Personal Information ***
    //* Basic Personal Information *
    basicPersonalInformation.firstName &&
    basicPersonalInformation.lastName &&
    basicPersonalInformation.sex &&
    //* Address *
    address.streetAndHouse &&
    address.city &&
    address.postIndex &&
    //* Tax Sata *
    taxData.taxId &&
    taxData.taxClass &&
    //* Social Insurance Data *
    socialInsurance.healthInsuranceCompany &&
    socialInsurance.pensionFundAccountNumber &&
    socialInsurance.healthInsuranceStatus &&
    //*** Organisational Information ***
    //* Organisation Specific Information *
    organisationSpecificInformation.personalNumber &&
    organisationSpecificInformation.personalPartition &&
    organisationSpecificInformation.startDate &&
    organisationSpecificInformation.positionNumber &&
    organisationSpecificInformation.costCenter &&
    organisationSpecificInformation.userId &&
    organisationSpecificInformation.email &&
    organisationSpecificInformation.telephone
  );
};

const valuesAreValid = (formData: FormType) => {
  return (
    ibanBasicPersonalInformationIsValid(formData) &&
    pensionFundAccountNumberIsValid(formData) &&
    birthdayIsValid(formData) &&
    familyStatusSinceInValid(formData) &&
    healthInsuranceSinceIsValid(formData) &&
    startDateIsValid(formData) &&
    dateConcernEnteredIsValid(formData) &&
    exitLimitationIsValid(formData) &&
    endOfEducationIsValid(formData) &&
    titleIsValid(formData) &&
    salutationIsValid(formData) &&
    nationalityIsValid(formData) &&
    familyStatusIsValid(formData) &&
    secondaryEducationIsValid(formData) &&
    professionalEducationIsValid(formData) &&
    taxClassIsValid(formData) &&
    confessionIsValid(formData) &&
    personGroupIsValid(formData) &&
    employeeCircleIsValid(formData) &&
    employeeGroupIsValid(formData) &&
    positionKeyIsValid(formData) &&
    workingHoursPatternIsValid(formData) &&
    typeOfInvestmentIsValid(formData)
  );
};

export const ibanBasicPersonalInformationIsValid = (
  formData: FormType,
  showEmptyRequiredFieldsInvalid: boolean = false
) => {
  const iban = formData.personalInformation.basicPersonalInformation.iban;
  if (iban) {
    return (
      iban.length < 34 &&
      //containes only alphanumeric characters
      /^[A-Za-z0-9]*$/.test(iban)
    );
  }
  if (showEmptyRequiredFieldsInvalid && !iban) {
    return false;
  }
  return true;
};

export const pensionFundAccountNumberIsValid = (
  formData: FormType,
  showEmptyRequiredFieldsInvalid: boolean = false
) => {
  const pensionFundAccountNumber =
    formData.personalInformation.socialInsuranceData.pensionFundAccountNumber;
  if (pensionFundAccountNumber) {
    return (
      pensionFundAccountNumber.length < 13 &&
      //containes only alphanumeric characters
      /^[A-Za-z0-9]*$/.test(pensionFundAccountNumber)
    );
  }
  if (showEmptyRequiredFieldsInvalid && !pensionFundAccountNumber) {
    return false;
  }
  return true;
};

export const birthdayIsValid = (
  formData: FormType,
  showEmptyRequiredFieldsInvalid: boolean = false
) => {
  const birthday = formData.personalInformation.basicPersonalInformation.birthday;
  if (birthday) {
    return validateDateFormat(birthday);
  }
  if (showEmptyRequiredFieldsInvalid && !birthday) {
    return false;
  }
  return true;
};

export const familyStatusSinceInValid = (formData: FormType) => {
  const familyStatusSince = formData.personalInformation.basicPersonalInformation.familyStatusSince;
  if (familyStatusSince) {
    return validateDateFormat(familyStatusSince);
  }
  return true;
};

export const healthInsuranceSinceIsValid = (
  formData: FormType,
  showEmptyRequiredFieldsInvalid: boolean = false,
  isDisabled: boolean = true
) => {
  const healthInsuranceSince =
    formData.personalInformation.socialInsuranceData.healthInsuranceSince;
  if (healthInsuranceSince) {
    return validateDateFormat(healthInsuranceSince);
  }
  if (showEmptyRequiredFieldsInvalid && !healthInsuranceSince && !isDisabled) {
    return false;
  }
  return true;
};

export const startDateIsValid = (
  formData: FormType,
  showEmptyRequiredFieldsInvalid: boolean = false
) => {
  const startDate = formData.organisationalInformation.organisationSpecificInformation.startDate;
  if (startDate) {
    return validateDateFormat(startDate);
  }
  if (showEmptyRequiredFieldsInvalid && !startDate) {
    return false;
  }
  return true;
};

export const dateConcernEnteredIsValid = (
  formData: FormType,
) => {
  const dateConcernEntered = formData.organisationalInformation.organisationSpecificInformation.dateConcernEntered;

  if (dateConcernEntered) {
    return validateDateFormat(dateConcernEntered);
  }
  return true;
};

export const exitLimitationIsValid = (
  formData: FormType,
) => {
  const exitLimitation = formData.organisationalInformation.organisationSpecificInformation.exitLimitation;

  if (exitLimitation) {
    return validateDateFormat(exitLimitation);
  }
  return true;
};

export const endOfEducationIsValid = (
  formData: FormType,
) => {
  const endOfEducation = formData.organisationalInformation.organisationSpecificInformation.endOfEducation;

  if (endOfEducation) {
    return validateDateFormat(endOfEducation);
  }
  return true;
};

const validateDateFormat = (date: string) => {
  //dd.mm.yyyy 1900-2099
  const dateFormatRegex = /(^0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.((?:19|20)\d{2})/;
  return date.length === 10 && dateFormatRegex.test(date);
};

export const titleIsValid = (formData: FormType) => {
  const title = formData.personalInformation.basicPersonalInformation.title;
  if (title) {
    return TITLE_VALUES.includes(title)
  }
  return true;
};

export const salutationIsValid = (formData: FormType) => {
  const salutation = formData.personalInformation.basicPersonalInformation.salutation;
  if (salutation) {
    return SALUTATION_VALUES.includes(salutation)
  }
  return true;
};

export const nationalityIsValid = (formData: FormType, showEmptyRequiredFieldsInvalid: boolean = false
  ) => {
  const nationality = formData.personalInformation.basicPersonalInformation.nationality;
  if (nationality) {
    return NATIONALITY_VALUES.includes(nationality)
  }
  if (showEmptyRequiredFieldsInvalid && !nationality) {
    return false;
  }
  return true;
};

export const familyStatusIsValid = (formData: FormType) => {
  const familyStatus = formData.personalInformation.basicPersonalInformation.familyStatus;
  if (familyStatus) {
    return FAMILY_STATUS_VALUES.includes(familyStatus)
  }
  return true;
};

export const secondaryEducationIsValid = (formData: FormType) => {
  const secondaryEducation = formData.personalInformation.basicPersonalInformation.secondaryEducation;
  if (secondaryEducation) {
    return SECONDARY_EDUCATION_VALUES.includes(secondaryEducation)
  }
  return true;
};

export const professionalEducationIsValid = (formData: FormType) => {
  const professionalEducation = formData.personalInformation.basicPersonalInformation.professionalEducation;
  if (professionalEducation) {
    return PROFESSIONAL_EDUCATION_VALUES.includes(professionalEducation)
  }
  return true;
};

export const taxClassIsValid = (formData: FormType, showEmptyRequiredFieldsInvalid: boolean = false
  ) => {
  const taxClass = formData.personalInformation.taxData.taxClass;
  if (taxClass) {
    return TAX_CLASS_VALUES.includes(taxClass)
  }
  if (showEmptyRequiredFieldsInvalid && !taxClass) {
    return false;
  }
  return true;
};

export const confessionIsValid = (formData: FormType) => {
  const confession = formData.personalInformation.taxData.confession;
  if (confession) {
    return CONFESSION_VALUES.includes(confession)
  }
  return true;
};

export const personGroupIsValid = (formData: FormType) => {
  const personGroup = formData.organisationalInformation.organisationSpecificInformation.personGroup;
  if (personGroup) {
    return PERSON_GROUP_VALUES.includes(personGroup)
  }
  return true;
};

export const employeeGroupIsValid = (formData: FormType) => {
  const employeeGroup = formData.organisationalInformation.organisationSpecificInformation.employeeGroup;
  if (employeeGroup) {
    return EMPLOYEE_GROUP_VALUES.includes(employeeGroup)
  }
  return true;
};

export const employeeCircleIsValid = (formData: FormType) => {
  const employeeCircle = formData.organisationalInformation.organisationSpecificInformation.employeeCircle;
  if (employeeCircle) {
    return EMPLOYEE_CIRCLE_VALUES.includes(employeeCircle)
  }
  return true;
};

export const positionKeyIsValid = (formData: FormType) => {
  const positionKey = formData.organisationalInformation.organisationSpecificInformation.positionKey;
  if (positionKey) {
    return POSITION_KEY_VALUES.includes(positionKey)
  }
  return true;
};

export const workingHoursPatternIsValid = (formData: FormType) => {
  const workingHoursPattern = formData.organisationalInformation.organisationSpecificInformation.workingHoursPattern;
  if (workingHoursPattern) {
    return WORKING_HOURS_PATTERN_VALUES.includes(workingHoursPattern)
  }
  return true;
};

export const typeOfInvestmentIsValid = (formData: FormType) => {
  const typeOfInvestment = formData.organisationalInformation.capitalFormationData.typeOfInvestment;
  if (typeOfInvestment) {
    return TYPE_OF_INVESTMENT_VALUES.includes(typeOfInvestment)
  }
  return true;
};
