import { CheckmarkOutline, PendingFilled, ProgressBarRound } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import OnboardingProcessesTable from "../../components/OnboardingProcessTable/OnboardingProcessesTable";
import { useOnboardingProcesses } from "../../hooks/useOnboardingProcesses";
import { Select, SelectItem, Tile } from "@carbon/react";
import { useState } from "react";

export default function AdminDashboard() {
  const { isLoading, onboardingProcesses } = useOnboardingProcesses();
  const navigate = useNavigate();
  const [mandantFilter, setMandantFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);

  //count onboardingProcesses with status "Überprüfung|unvollständig" that does not create new array

  const openProcesses = onboardingProcesses.filter((oP) => {
    return (oP.status === "checking" ||
      oP.status === "incomplete" ||
      oP.status === "Überprüfung" ||
      oP.status === "unvollständig") &&
      (mandantFilter
      ? oP.employer === mandantFilter
      : true);
  }).length;
  const finishedProcesses = onboardingProcesses.filter((oP) => {
    return (oP.status === "finished" || oP.status === "abgeschlossen") && (mandantFilter
      ? oP.employer === mandantFilter
      : true);
  }).length;

  const checkingTableHeaders = [
    { header: "Vorname", key: "firstName" },
    { header: "Nachname", key: "lastName" },
    { header: "Personalnummer", key: "employeeNumber" },
    { header: "Kunde", key: "employer" },
    { header: "Eintrittsdatum", key: "hireDate" },
    { header: "Status", key: "status" },
    { header: "Bot", key: "botStatus" },
  ];
  const checkingTitle = <>Übersicht aller Onboarding Vorgänge</>;

  return (
    <>
      <div className="flex justify-center">
        <div className="w-[960px] mb-32">
          <h2 className="mt-20 text-xl mb-7">Onboarding Dashboard</h2>
          <Select
            id="title"
            labelText="Filtern Sie die Vorgänge nach Ihren Mandanten"
            className="w-[290px] mb-8"
            defaultValue={undefined}
            size="sm"
            onChange={(e) => setMandantFilter(e.target.value)}
          >
            <SelectItem value={undefined} text="Bitte auswählen" />
            <SelectItem value={"Beste Bank"} text="Beste Bank" />
            <SelectItem value={"Allerbeste Bank"} text="Allerbeste Bank" />
          </Select>

          <div className="flex flex-row mb-14 gap-8">
            <Tile
              className={`w-60 h-32 cursor-pointer ${
                statusFilter === "Überprüfung|unvollständig"
                  ? " outline-solid outline-2 outline-primary"
                  : ""
              }`}
              onClick={() =>
                setStatusFilter((prevStatus) => {
                  return prevStatus === "Überprüfung|unvollständig"
                    ? undefined
                    : "Überprüfung|unvollständig";
                })
              }
            >
              <p>Offene Vorgänge</p>
              <p className="text-4xl mt-5">{openProcesses}</p>
            </Tile>
            <Tile
              className={`w-60 h-32 cursor-pointer ${
                statusFilter === "abgeschlossen" ? " outline-solid outline-2 outline-primary" : ""
              }`}
              onClick={() =>
                setStatusFilter((prevStatus) => {
                  return prevStatus === "abgeschlossen" ? undefined : "abgeschlossen";
                })
              }
            >
              <p>Abgeschlossene Vorgänge</p>
              <p className="text-4xl mt-5">{finishedProcesses}</p>
            </Tile>
          </div>

          <OnboardingProcessesTable
            isLoading={isLoading}
            onboardingProcesses={onboardingProcesses}
            headers={checkingTableHeaders}
            title={checkingTitle}
            filters={[
              "Überprüfung|unvollständig|abgeschlossen",
              mandantFilter ? mandantFilter : "",
              statusFilter ? statusFilter : "",
            ]}
            onClickRow={(id) => navigate(`/admin/processes/${id}`)}
          />
        </div>
      </div>
    </>
  );
}
