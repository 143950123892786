import React, { useEffect, useState } from "react";
import {
  Select,
  SelectItem,
  TextInput,
  DatePicker,
  DatePickerInput,
  Toggle,
  NumberInput,
} from "@carbon/react";
import { generateSelectItems } from "../../utils/elementGenerator";
import {
  PERSON_GROUP_VALUES,
  EMPLOYEE_GROUP_VALUES,
  EMPLOYEE_CIRCLE_VALUES,
  WORKING_HOURS_PATTERN_VALUES,
  POSITION_KEY_VALUES,
} from "../../types/FormData";
import { useFormState } from "../../atoms/useFormState";
import { format } from "date-fns";
import { useShowInvalidFieldsSave } from "../../atoms/useShowInvalidFieldsSave";
import {
  dateConcernEnteredIsValid,
  employeeCircleIsValid,
  employeeGroupIsValid,
  endOfEducationIsValid,
  exitLimitationIsValid,
  personGroupIsValid,
  positionKeyIsValid,
  startDateIsValid,
  workingHoursPatternIsValid,
} from "../../utils/validator";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";

export default function OrganisationSpecificInformationForm() {
  const [formState, setFormState] = useFormState();
  const [showInvalidFieldsStartBot, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();
  const [showInvalidFieldsSave, setShowInvalidFieldsSave] = useShowInvalidFieldsSave();
  const [dateConcernEnteredIsDisabled, setDateConcernEnteredIsDisabled] = useState<boolean>(true);
  const [withRetirementInsuranceIsDisabled, setWithRetirementInsuranceIsDisabled] =
    useState<boolean>(true);
  const [exitLimitationIsDisabled, setExitLimitationIsDisabled] = useState<boolean>(true);
  const [endOfEducationIsDisabled, setEndOfEducationIsDisabled] = useState<boolean>(true);
  const [otherPositionKeyIsDisabled, setOtherPositionKeyIsDisabled] = useState<boolean>(true);
  const organisationSpecificInformation =
    formState.organisationalInformation.organisationSpecificInformation;

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const handleDateChange = (e, key) => {
    changeFormStateProperty(key, format(e[0], "dd.MM.yyyy"));
  };

  const handleOtherChanges = (e, key) => {
    changeFormStateProperty(key, e);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      organisationalInformation: {
        ...formState.organisationalInformation,
        organisationSpecificInformation: {
          ...formState.organisationalInformation.organisationSpecificInformation,
          [propertyName]: propertyValue,
        },
      },
    });
  };

  // Resetting Form Values:
  // Wrapped in useEffect to avoid update errors from parent component

  useEffect(() => {
    setDateConcernEnteredIsDisabled(getDateConcernEnteredIsDisabled());
    setWithRetirementInsuranceIsDisabled(getWithRetirementInsuranceIsDisabled());
    setExitLimitationIsDisabled(getExitLimitationIsDisabled());
    setEndOfEducationIsDisabled(getEndOfEducationIsDisabled());
    setOtherPositionKeyIsDisabled(getOtherPositionKeyIsDisabled());
  }, [organisationSpecificInformation]);

  const getDateConcernEnteredIsDisabled = (): boolean => {
    if (organisationSpecificInformation.newInConcern) {
      resetProperty("dateConcernEntered");
      return true;
    }
    return false;
  };

  const getWithRetirementInsuranceIsDisabled = (): boolean => {
    if (!organisationSpecificInformation.minorEmployment) {
      resetProperty("withRetirementInsurance");
      return true;
    }
    return false;
  };

  const getExitLimitationIsDisabled = (): boolean => {
    if (!organisationSpecificInformation.shortTimeEmployment) {
      resetProperty("exitLimitation");
      return true;
    }
    return false;
  };

  const getEndOfEducationIsDisabled = (): boolean => {
    if (!PERSON_GROUP_VALUES.includes("Auszubildente")) {
      throw new TypeError("PERSON_GROUP_VALUES don't match business logic");
    }

    if (organisationSpecificInformation.personGroup !== "Auszubildente") {
      resetProperty("endOfEducation");
      return true;
    }
    return false;
  };

  const getOtherPositionKeyIsDisabled = (): boolean => {
    if (!POSITION_KEY_VALUES.includes("Sonstiges")) {
      throw new TypeError("POSITION_KEY_VALUES don't match business logic");
    }

    if (organisationSpecificInformation.positionKey !== "Sonstiges") {
      resetProperty("otherPositionKey");
      return true;
    }
    return false;
  };

  const resetProperty = (property: string) => {
    switch (typeof organisationSpecificInformation[property]) {
      case "boolean": {
        if (organisationSpecificInformation[property] !== false) {
          changeFormStateProperty(property, false);
        }
        break;
      }
      case "number": {
        if (organisationSpecificInformation[property] !== 0) {
          changeFormStateProperty(property, 0);
        }
        break;
      }
      default: {
        if (organisationSpecificInformation[property] !== "") {
          changeFormStateProperty(property, "");
        }
        break;
      }
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 w-full mt-4 pl-8 pr-8 pb-4">
      <TextInput
        invalid={showInvalidFieldsSave && !organisationSpecificInformation.personalNumber}
        invalidText="Bitte geben Sie eine Personalnummer ein."
        name="personalNumber"
        id="personalNumber"
        type="text"
        labelText="Personalnummer*"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.personalNumber}
      />

      <TextInput
        name="personalPartition"
        id="personalPartition"
        type="text"
        labelText="Teilbereich*"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.personalPartition}
        invalid={!organisationSpecificInformation.personalPartition && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Personalteilbereich ein."
      />

      <DatePicker
        datePickerType="single"
        dateFormat="d.m.Y"
        onChange={(e) => handleDateChange(e, "startDate")}
      >
        <DatePickerInput
          placeholder="tt.mm.jjjj"
          labelText="Eintrittsdatum*"
          id="startDate"
          size="sm"
          onChange={handleChange}
          defaultValue={organisationSpecificInformation.startDate}
          invalid={!startDateIsValid(formState, showInvalidFieldsSave)}
          invalidText="Bitte geben Sie das Datum im validen Format ein."
        />
      </DatePicker>

      <TextInput
        name="positionNumber"
        id="positionNumber"
        type="text"
        labelText="Planstellennummer"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.positionNumber}
        invalid={!organisationSpecificInformation.positionNumber && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Planstellennummer ein."
      />

      <div className="grid grid-cols-2 gap-3">
        <Toggle
          className="col-span-1"
          labelText="Konzerneintritt"
          labelA="Nein"
          labelB="Ja"
          id="newInConcern"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "newInConcern")}
          defaultToggled={organisationSpecificInformation.newInConcern}
        />
        <DatePicker
          datePickerType="single"
          dateFormat="d.m.Y"
          onChange={(e) => handleDateChange(e, "dateConcernEntered")}
        >
          <DatePickerInput
            placeholder="tt.mm.jjjj"
            labelText="Datum Konzerneintritt"
            id="dateConcernEntered"
            size="sm"
            onChange={handleChange}
            defaultValue={organisationSpecificInformation.dateConcernEntered}
            disabled={dateConcernEnteredIsDisabled}
            invalid={!dateConcernEnteredIsValid(formState)}
            invalidText="Bitte geben Sie das Datum im validen Format ein."
          />
        </DatePicker>
      </div>

      <TextInput
        name="costCenter"
        id="costCenter"
        type="text"
        labelText="Kostenstellen Nr."
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.costCenter}
        invalid={!organisationSpecificInformation.costCenter && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Kostenstellen Nr. ein."
      />

      <Select
        id="personGroup"
        labelText="Personengruppe (Azubi etc.)"
        defaultValue={organisationSpecificInformation.personGroup}
        size="sm"
        onChange={handleChange}
        invalid={!personGroupIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(PERSON_GROUP_VALUES)}
      </Select>

      <Select
        id="employeeGroup"
        labelText="Mitarbeitergruppe"
        defaultValue={organisationSpecificInformation.employeeGroup}
        size="sm"
        onChange={handleChange}
        invalid={!employeeGroupIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(EMPLOYEE_GROUP_VALUES)}
      </Select>

      <div className="grid grid-cols-2 gap-3">
        <Toggle
          labelText="Kurzfristige Beschäftigung"
          labelA="Nein"
          labelB="Ja"
          id="shortTimeEmployment"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "shortTimeEmployment")}
          defaultToggled={organisationSpecificInformation.shortTimeEmployment}
        />
        <DatePicker
          datePickerType="single"
          dateFormat="d.m.Y"
          size="sm"
          onChange={(e) => handleDateChange(e, "exitLimitation")}
        >
          <DatePickerInput
            placeholder="tt.mm.jjjj"
            labelText="Befristung"
            id="exitLimitation"
            size="sm"
            onChange={handleChange}
            defaultValue={organisationSpecificInformation.exitLimitation}
            disabled={exitLimitationIsDisabled}
            invalid={!exitLimitationIsValid(formState)}
            invalidText="Bitte geben Sie das Datum im validen Format ein."
          />
        </DatePicker>
      </div>

      <Select
        id="employeeCircle"
        labelText="Mitarbeiterkreis"
        defaultValue={organisationSpecificInformation.employeeCircle}
        size="sm"
        onChange={handleChange}
        invalid={!employeeCircleIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(EMPLOYEE_CIRCLE_VALUES)}
      </Select>

      {/*TODO: clicking on increment and decrement icons does not change formState. */}
      <NumberInput
        id="workingHoursWeekly"
        min={0}
        value={organisationSpecificInformation.workingHoursWeekly}
        label="Arbeitszeit pro Woche in h*"
        invalidText="Bitte Anzahl von Stunden eingeben"
        size="sm"
        onChange={handleChange}
      />

      <DatePicker
        datePickerType="single"
        dateFormat="d.m.Y"
        onChange={(e) => handleDateChange(e, "endOfEducation")}
      >
        <DatePickerInput
          placeholder="tt.mm.jjjj"
          labelText="Ende Ausbildung"
          id="endOfEducation"
          size="sm"
          onChange={handleChange}
          defaultValue={organisationSpecificInformation.endOfEducation}
          disabled={endOfEducationIsDisabled}
          invalid={!endOfEducationIsValid(formState)}
          invalidText="Bitte geben Sie das Datum im validen Format ein."
        />
      </DatePicker>

      <div className="grid grid-cols-2 gap-4 w-full">
        <Toggle
          labelText="Übergangsbereich"
          labelA="Nein"
          labelB="Ja"
          id="transitionField"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "transitionField")}
          defaultToggled={organisationSpecificInformation.transitionField}
        />

        <Toggle
          labelText="Mehrfachbeschäftigung"
          labelA="Nein"
          labelB="Ja"
          id="multiEmployment"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "multiEmployment")}
          defaultToggled={organisationSpecificInformation.multiEmployment}
        />
      </div>

      <Select
        id="positionKey"
        labelText="Tätigkeitsschlüssel und Bezeichnung"
        defaultValue={organisationSpecificInformation.positionKey}
        size="sm"
        onChange={handleChange}
        invalid={!positionKeyIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(POSITION_KEY_VALUES)}
      </Select>

      <div className="grid grid-cols-2 gap-4 w-full">
        <Toggle
          labelText="Geringfügige Beschäftigung"
          labelA="Nein"
          labelB="Ja"
          id="minorEmployment"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "minorEmployment")}
          defaultToggled={organisationSpecificInformation.minorEmployment}
        />

        <Toggle
          labelText="Mit Rentenversicherung?"
          labelA="Nein"
          labelB="Ja"
          id="withRetirementInsurance"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "withRetirementInsurance")}
          defaultToggled={organisationSpecificInformation.withRetirementInsurance}
          disabled={withRetirementInsuranceIsDisabled}
        />
      </div>

      <TextInput
        name="otherPositionKey"
        id="otherPositionKey"
        type="text"
        labelText="Sonstige Tätigkeitsschlüssel"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.otherPositionKey}
        disabled={otherPositionKeyIsDisabled}
      />

      <Select
        id="workingHoursPattern"
        labelText="Arbeitszeitplanregel"
        defaultValue={organisationSpecificInformation.workingHoursPattern}
        size="sm"
        onChange={handleChange}
        invalid={!workingHoursPatternIsValid(formState)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem value={""} text="Bitte auswählen" />
        {generateSelectItems(WORKING_HOURS_PATTERN_VALUES)}
      </Select>

      <TextInput
        name="userId"
        id="userId"
        type="text"
        labelText="UserID*"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.userId}
        invalid={!organisationSpecificInformation.userId && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie User ID ein."
      />
      <TextInput
        name="email"
        id="email"
        type="text"
        labelText="Email*"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.email}
        invalid={!organisationSpecificInformation.email && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Email ein."
      />
      <TextInput
        name="telephone"
        id="telephone"
        type="text"
        labelText="Tel. am Arbeitsplatz*"
        size="sm"
        onChange={handleChange}
        defaultValue={organisationSpecificInformation.telephone}
        invalid={!organisationSpecificInformation.telephone && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Tel. am Arbeitsplatz ein."
      />
    </div>
  );
}
