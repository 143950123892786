export type FormType = {
    personalInformation: PersonalInformationType
    organisationalInformation: OrganisationalInformationType
}

export type PersonalInformationType = {
     basicPersonalInformation: BasicPersonalInformationType
     address: AddressType
     taxData: TaxDataType
     socialInsuranceData: SocialInsuranceDataType
}

export type OrganisationalInformationType = {
    organisationSpecificInformation: OrganisationSpecificInformationType
    salaryData: SalaryType
    capitalFormationData: CapitalFormationType
}

export type BasicPersonalInformationType = {
    salutation?: Salutation
    title?: Title
    firstName?: string
    lastName?: string
    birthName?: string
    birthday?: string
    placeOfBirth?: string
    nationality?: Nationality
    sex?: Sex
    iban?: string
    familyStatus?: FamilyStatus
    familyStatusSince?: string
    secondaryEducation?: SecondaryEducation
    professionalEducation?: ProfessionalEducation
    disability?: boolean

}

//------ BasicPersonalInformation Types Start ------
export const SALUTATION_VALUES = ["Frau", "Herr", "Andere"]
type Salutation = typeof SALUTATION_VALUES[number];

export const TITLE_VALUES = ["Dr.", "Dr. Dr.", "Prof. Dr.", "Prof. Dr. Dr."]
type Title = typeof TITLE_VALUES[number];

//TODO: the list is not complete. Complete it if needed
export const NATIONALITY_VALUES = ["deutsch", "dominikanisch", "dschibutisch", "equadorianisch", "emir", "eritreisch", "estnisch", "finnisch"]
type Nationality = typeof NATIONALITY_VALUES[number];

export const SEX_VALUES = ["weiblich", "männlich", "divers"]
type Sex = typeof SEX_VALUES[number];

export const FAMILY_STATUS_VALUES = ["ledig", "verheiratet", "getrennt", "verwitwet", "geschieden"]
type FamilyStatus = typeof FAMILY_STATUS_VALUES[number];

export const SECONDARY_EDUCATION_VALUES = ["Ohne Schulabschluss", "Haupt-/Volkschulabschluss", "Mittlere Reife oder gleichwertiger Abschluss", "Abitur / Fachabitur", "Abschluss unbekannt"]
type SecondaryEducation = typeof SECONDARY_EDUCATION_VALUES[number];

export const PROFESSIONAL_EDUCATION_VALUES = ["Ohne Schulabschluss", "Ausbildung", "Meister / Techniker", "Bachelor", "Diplom / Master / Staatsexamen", "Promotion", "Unbekannt"]
type ProfessionalEducation = typeof PROFESSIONAL_EDUCATION_VALUES[number];
//------ BasicPersonalInformation Types End ------

export type AddressType = {
    streetAndHouse?: string
    city?: string
    postIndex?: string
    additionalAddressInformation?: string
}

export type TaxDataType = {
    taxId?: string
    taxClass?: TaxClass
    taxClassFactor?: string
    churchTax?: boolean
    confession?: Confession
    children?: boolean
    childAllowance?: boolean
    childAllowanceFactor?: string
}

//------ TaxDataType Types Start ------
export const TAX_CLASS_VALUES = ["1", "2", "3", "4", "5", "6"]
type TaxClass = typeof TAX_CLASS_VALUES[number];

//TODO: the list is not complete. Complete it if needed
export const CONFESSION_VALUES = ["evangelisch", "römisch-katholisch", "reformiert (evangelisch)", "französich-reformiert", "altkatholisch", "israeltisch"]
type Confession = typeof CONFESSION_VALUES[number];

//------ TaxDataType Types End ------

export type SocialInsuranceDataType = {
    healthInsuranceCompany?: string
    pensionFundAccountNumber?: string
    healthInsuranceStatus?: HealthInsuranceStatus
    healthInsuranceSince?: string
    lastStateInsuranceCompany?: string
}

//------ SocialInsuranceDataType Types Start ------
export const HEALTH_INSURANCE_STATUS_VALUES = ["Gesetzlich", "Freiwillig", "Privat"]
type HealthInsuranceStatus = typeof HEALTH_INSURANCE_STATUS_VALUES[number];

//------ SocialInsuranceDataType Types End ------

export type OrganisationSpecificInformationType = {
     personalNumber?: string
     personalPartition?: string
     startDate?: string
     positionNumber?: string
     newInConcern?: boolean
     dateConcernEntered?: string
     costCenter?: string
     personGroup?: PersonGroup
     employeeGroup?: EmployeeGroup
     exitLimitation?: string
     employeeCircle?: EmployeeCircle
     workingHoursWeekly?: number
     endOfEducation?: string
     transitionField?: boolean
     shortTimeEmployment?: boolean
     positionKey?: PositionKey
     otherPositionKey?: string
     minorEmployment?: boolean
     withRetirementInsurance?: boolean
     multiEmployment?: boolean
     workingHoursPattern?: WorkingHoursPattern
     email?: string
     telephone?: string
     userId?: string
}

//------ OrganisationSpecificInformationType Types Start ------

//TODO: the list is not complete. Complete it if needed
export const PERSON_GROUP_VALUES = ["SV-Pflichtige", "Auszubildente", "Altersteilzeit", "Hausgewerbetreibende", "Praktikanten", "Werkstudenten"]
type PersonGroup = typeof PERSON_GROUP_VALUES[number];

export const EMPLOYEE_GROUP_VALUES = ["Beschäftigte", "Rentner/Pensionisten", "Vorruheständler", "Hinterbl./Sterbegeld", "Ruhendes Arbeitsver.", "Alterteilszeit aktiv", "Alterteilszeit pasiv", "Anwartschaften", "Externe Minarbeiter"]
type EmployeeGroup = typeof EMPLOYEE_GROUP_VALUES[number];

//TODO: the list is not complete. Complete it if needed
export const EMPLOYEE_CIRCLE_VALUES = ["Stundenlohn (SL)", "Aushilfe (SL)", "Werkstudenten (SL)", "Reinigungskräfte (SL)", "Tarifl. Angestellte"]
type EmployeeCircle = typeof EMPLOYEE_CIRCLE_VALUES[number];

export const POSITION_KEY_VALUES = [
    "72112 Bankkaufleute - fachlich ausgerichtete Tätigkeiten",
    "34102 Berufe in der Gebäudetechnik (ohne Spezialisierung) - fachlich ausgerichtete Tätigkeit",
    "51311 Berufe in der Lagerwirtschaft - Helfertätigkeiten/Anlerntätigkeiten",
    "54101 Berufe in der Reinigung(ohne Spezialisierung) - Helfertätigkeiten/Anlerntätigkeiten",
    "71402 Büro-und Sekretariatskräfte (ohne Spezialisierung) - fachlich ausgerichtete Tätigkeiten",
    "61312 Berufe in der Immobilienvermarktung & -verwaltung - fachlich ausgerichtete Tätigkeiten",
    "72122 Anlageberater/innen - & sonstige Finanzdienstleistungsberufe - fachlich ausgerichtete Tätigkeiten",
    "63112 Tourismuskaufleute - fachlich ausgerichtete Tätigkeiten",
    "51312 Berufe in der Lagerwirtschaft - fachlich ausgerichtete Tätigkeiten",
    "52122 Berufskraftfahrer/innen (Güterverkehr/LKW) - fachlich ausgerichtete Tätigkeiten",
    "25222 Berufe in der Land- & Baumaschinentechnik - fachlich ausgerichtete Tätigkeiten",
    "61212 Kaufleute im Groß- & Außenhandel - fachlich ausgerichtete Tätigkeiten",
    "62262 Berufe im Verkauf von Garten-, Heimwerker-, Haustier- & Zoobedarf - fachlich ausgerichtete Tätigkeiten",
    "71104 Geschäftsführer/innen & Vorstände - hoch komplexe Tätigkeiten",
    "Sonstiges"
]
type PositionKey = typeof POSITION_KEY_VALUES[number];

export const WORKING_HOURS_PATTERN_VALUES = ["38h/W", "39h/W", "40h/W", "Sonstige"]
type WorkingHoursPattern = typeof WORKING_HOURS_PATTERN_VALUES[number];

//------ OrganisationSpecificInformationType Types End ------

export type SalaryType = {
     group?: string
     level?: string
     allowance1?: string
     allowanceAmount1?: string
     allowance2?: string
     allowanceAmount2?: string
     hourlySalary?: number
     flatTaxGfbKfb?: boolean
     flatTaxEmployerCoverage?: boolean
}

//------ SalaryType Types End ------

export type CapitalFormationType = {
    vlAgShare?: string
    vlPayment?: string
    typeOfInvestment?: TypeOfInverstment
    vlContractNumber?: string
    receiver?: string
    iban?: string
}

//------ CapitalFormationType Types Start ------
export const TYPE_OF_INVESTMENT_VALUES = ["Bausparen", "Kapitalsparen", "AVmG", "BVV", "LAZ", "Ratensparen", "Lebensversicherung", "Darlehen"]
type TypeOfInverstment = typeof TYPE_OF_INVESTMENT_VALUES[number];

//------ CapitalFormationType Types End ------