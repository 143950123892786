import { useEffect, useState } from "react";
import { getOnboardingProcesses } from "../api/getOnboardingProcesses";
import { useUser } from "../atoms/useUser";
import { EmployeeData } from "../types/OnboardingProcess";

export const useOnboardingProcesses = () => {
  const [onboardingProcesses, setOnboardingProcesses] = useState<EmployeeData[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, _] = useUser();

  useEffect(() => {
    const fetchOnboardingProcesses = async (userName: string) => {
      //TODO: Remove this hack
      if (userName === "Barbara") {
        userName = "";
      }
      const onboardingProcesses = await getOnboardingProcesses(userName);
      setOnboardingProcesses(onboardingProcesses);
      setIsLoading(false);
    };

    if (user) {
      fetchOnboardingProcesses(user);
      const interval = setInterval(() => {
        fetchOnboardingProcesses(user);
      }, 5000);

      return () => clearInterval(interval);
    }

    return;
  }, [user]);

  return { isLoading, onboardingProcesses };
};
