import { TextInput, Select, SelectItem, Toggle } from "@carbon/react";
import { generateSelectItems } from "../../utils/elementGenerator";
import { CONFESSION_VALUES, TAX_CLASS_VALUES } from "../../types/FormData";
import { useFormState } from "../../atoms/useFormState";
import { confessionIsValid, taxClassIsValid } from "../../utils/validator";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";
import { useEffect, useState } from "react";

export default function TaxDataForm() {
  const [formState, setFormState] = useFormState();
  const taxDataFormState = formState.personalInformation.taxData;
  const [showInvalidFieldsStartBot, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();
  const [taxClassFactorIsDisabled, setTaxClassFactorIsDisabled] = useState<boolean>(true);
  const [confessionIsDisabled, setConfessionIsDisabled] = useState<boolean>(true);
  const [childAllowanceIsDisabled, setChildAllowanceIsDisabled] = useState<boolean>(true);
  const [childAllowanceFactorIsDisabled, setChildAllowanceFactorIsDisabled] =
    useState<boolean>(true);

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const handleOtherChanges = (e, key) => {
    changeFormStateProperty(key, e);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      personalInformation: {
        ...formState.personalInformation,
        taxData: { ...formState.personalInformation.taxData, [propertyName]: propertyValue },
      },
    });
  };

  // Resetting Form Values:
  // Wrapped in useEffect to avoid update errors from parent component
  useEffect(() => {
    setTaxClassFactorIsDisabled(getTaxClassFactorIsDisabled());
    setConfessionIsDisabled(getConfessionIsDisabled());
    setChildAllowanceIsDisabled(getChildAllowanceIsDisabled());
    setChildAllowanceFactorIsDisabled(getChildAllowanceFactorIsDisabled());
  }, [taxDataFormState]);

  const getTaxClassFactorIsDisabled = (): boolean => {
    if (!TAX_CLASS_VALUES.includes("4")) {
      throw new TypeError("TAX_CLASS_VALUES don't match business logic");
    }
    if (taxDataFormState.taxClass !== "4") {
      resetProperty("taxClassFactor");
      return true;
    }
    return false;
  };

  const getConfessionIsDisabled = (): boolean => {
    if (!taxDataFormState.churchTax) {
      resetProperty("confession");
      return true;
    }
    return false;
  };

  const getChildAllowanceIsDisabled = (): boolean => {
    if (!taxDataFormState.children) {
      resetProperty("childAllowance");
      return true;
    }
    return false;
  };

  const getChildAllowanceFactorIsDisabled = (): boolean => {
    if (!taxDataFormState.childAllowance) {
      resetProperty("childAllowanceFactor");
      return true;
    }
    return false;
  };

  const resetProperty = (property: string) => {
    switch (typeof taxDataFormState[property]) {
      case "boolean": {
        if (taxDataFormState[property] !== false) {
          changeFormStateProperty(property, false);
        }
        break;
      }
      case "number": {
        if (taxDataFormState[property] !== 0) {
          changeFormStateProperty(property, 0);
        }
        break;
      }
      default: {
        if (taxDataFormState[property] !== "") {
          changeFormStateProperty(property, "");
        }
        break;
      }
    }
  };

  return (
    <div className="pl-8 pr-8 grid grid-cols-1 gap-4 w-full mt-4">
      <TextInput
        id="taxId"
        type="text"
        labelText="Steuer ID*"
        size="sm"
        onChange={handleChange}
        defaultValue={taxDataFormState.taxId}
        invalid={!taxDataFormState.taxId && showInvalidFieldsStartBot}
        invalidText="Bitte geben Sie Steuer ID ein."
      />

      <Select
        id="taxClass"
        labelText="Steuerklasse*"
        defaultValue={taxDataFormState.taxClass}
        size="sm"
        onChange={handleChange}
        invalid={!taxClassIsValid(formState, showInvalidFieldsStartBot)}
        invalidText="Wählen Sie eine Option aus der Liste"
      >
        <SelectItem hidden value={""} text="Bitte auswählen" />
        {generateSelectItems(TAX_CLASS_VALUES)}
      </Select>

      <TextInput
        id="taxClassFactor"
        type="text"
        labelText="Faktor (Steuerklasse 4)"
        size="sm"
        onChange={handleChange}
        defaultValue={taxDataFormState.taxClassFactor}
        disabled={taxClassFactorIsDisabled}
      />

      <div className="grid grid-cols-4 gap-3">
        <Toggle
          className="col-span-1"
          labelText="Kirchensteuer*"
          labelA="Nein"
          labelB="Ja"
          id="churchTax"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "churchTax")}
          defaultToggled={taxDataFormState.churchTax}
        />
        <Select
          className="col-span-3"
          id="confession"
          labelText="Konfession"
          defaultValue={taxDataFormState.confession}
          size="sm"
          onChange={handleChange}
          disabled={confessionIsDisabled}
          invalid={!confessionIsValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(CONFESSION_VALUES)}
        </Select>
      </div>

      <div className="grid grid-cols-4 gap-3">
        <Toggle
          className="col-span-1"
          labelText="Kinder*"
          labelA="Nein"
          labelB="Ja"
          id="children"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "children")}
          defaultToggled={taxDataFormState.children}
        />

        <Toggle
          className="col-span-3"
          labelText="Kinderfreibetrag*"
          labelA="Nein"
          labelB="Ja"
          id="childAllowance"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "childAllowance")}
          defaultToggled={taxDataFormState.childAllowance}
          disabled={childAllowanceIsDisabled}
        />
      </div>

      <TextInput
        id="childAllowanceFactor"
        type="text"
        labelText="Faktor (Kinderfreibetrag)"
        size="sm"
        onChange={handleChange}
        defaultValue={taxDataFormState.childAllowanceFactor}
        disabled={childAllowanceFactorIsDisabled}
      />
    </div>
  );
}
