import React from "react";
import { SelectItem, RadioButton } from "@carbon/react";

export function generateSelectItems(itemNames: string[]) {
  var items: JSX.Element[] = [];
  for (let i = 0; i < itemNames.length; i++) {
    items.push(<SelectItem value={itemNames[i]} text={itemNames[i]} key={itemNames[i]} />);
  }
  return items;
}

export function generateRadioButtons(radioButtonNames: string[]) {
  var items: JSX.Element[] = [];
  for (let i = 0; i < radioButtonNames.length; i++) {
    items.push(
      <RadioButton
        key={radioButtonNames[i]}
        labelText={radioButtonNames[i]}
        value={radioButtonNames[i]}
        id={radioButtonNames[i]}
      />
    );
  }
  return items;
}
