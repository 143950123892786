import React from "react";
import { Accordion, AccordionItem } from "@carbon/react";
import OrganisationSpecificInformationForm from "./OrganisationSpecificInformationForm";
import SalaryForm from "./SalaryForm";
import CapitalFormationForm from "./CapitalFormationForm";

export default function OrganisationalInformation() {
  return (
    <div className="mb-12">
      <Accordion className="pt-4">
        <AccordionItem open={true} title="Organisationsspezifische Informationen">
          <OrganisationSpecificInformationForm />
        </AccordionItem>
        <div className="grid grid-cols-2 w-full">
          <div>
            <AccordionItem title="Bezüge">
              <SalaryForm />
            </AccordionItem>
          </div>
          <div>
            <AccordionItem title="Vermögensbildung">
              <CapitalFormationForm />
            </AccordionItem>
          </div>
        </div>
      </Accordion>
    </div>
  );
}
