import React, { useEffect, useState } from "react";
import {
  Select,
  SelectItem,
  TextInput,
  DatePicker,
  DatePickerInput,
  RadioButtonGroup,
  Toggle,
  IconButton,
} from "@carbon/react";
import { Upload } from "@carbon/react/icons";
import "./Form.scss";
import { useFormState } from "../../atoms/useFormState";
import { generateRadioButtons, generateSelectItems } from "../../utils/elementGenerator";
import {
  SALUTATION_VALUES,
  TITLE_VALUES,
  NATIONALITY_VALUES,
  SECONDARY_EDUCATION_VALUES,
  PROFESSIONAL_EDUCATION_VALUES,
  SEX_VALUES,
  FAMILY_STATUS_VALUES,
} from "../../types/FormData";
import { format } from "date-fns";
import { useShowInvalidFieldsSave } from "../../atoms/useShowInvalidFieldsSave";
import {
  birthdayIsValid,
  familyStatusSinceInValid,
  ibanBasicPersonalInformationIsValid,
  nationalityIsValid,
  professionalEducationIsValid,
  salutationIsValid,
  secondaryEducationIsValid,
  titleIsValid,
} from "../../utils/validator";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";

export default function BasicInformationForm() {
  const [showInvalidFieldsSave, setShowInvalidFieldsSave] = useShowInvalidFieldsSave();
  const [showInvalidFieldsStartBot, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();
  const [familyStatusSinceIsDisabled, setFamilyStatusSinceIsDisabled] = useState<boolean>(true);
  
  const [formState, setFormState] = useFormState();
  const basicInformationFormState = formState.personalInformation.basicPersonalInformation;
  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const handleDateChange = (e, key) => {
    changeFormStateProperty(key, format(e[0], "dd.MM.yyyy"));
  };

  const handleOtherChanges = (e, key) => {
    changeFormStateProperty(key, e);
  };

  //TODO: accepts all keys, not only defined in data model
  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      personalInformation: {
        ...formState.personalInformation,
        basicPersonalInformation: {
          ...formState.personalInformation.basicPersonalInformation,
          [propertyName]: propertyValue,
        },
      },
    });
  };

  // Resetting Form Values:
  // Wrapped in useEffect to avoid update errors from parent component

  useEffect(() => {
    setFamilyStatusSinceIsDisabled(getFamilyStatusSinceIsDisabled());
  }, [basicInformationFormState]);


  const getFamilyStatusSinceIsDisabled = (): boolean => {
    const activationValues = ["geschieden", "verwitwet", "verheiratet"];
    if (!activationValues.every((i) => FAMILY_STATUS_VALUES.includes(i))) {
      throw new TypeError("FAMILY_STATUS_VALUES don't match business logic");
    }

    if (
      !basicInformationFormState.familyStatus ||
      !activationValues.includes(basicInformationFormState.familyStatus)
    ) {
      resetProperty("familyStatusSince");
      return true;
    }
    return false;
  };

  const resetProperty = (property: string) => {
    switch (typeof basicInformationFormState[property]) {
      case "boolean": {
        if (basicInformationFormState[property] !== false) {
          changeFormStateProperty(property, false);
        }
        break;
      }
      case "number": {
        if (basicInformationFormState[property] !== 0) {
          changeFormStateProperty(property, 0);
        }
        break;
      }
      default: {
        if (basicInformationFormState[property] !== "") {
          changeFormStateProperty(property, "");
        }
        break;
      }
    }
  };

  return (
    <div className="w-full pl-8 pr-8 pb-4 mt-4">
      <div className="grid grid-cols-2 gap-4 w-full">
        <Select
          id="salutation"
          labelText="Anrede"
          defaultValue={basicInformationFormState.salutation}
          size="sm"
          onChange={handleChange}
          invalid={!salutationIsValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(SALUTATION_VALUES)}
        </Select>

        <Select
          id="title"
          labelText="Titel"
          defaultValue={basicInformationFormState.title}
          size="sm"
          onChange={handleChange}
          invalid={!titleIsValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(TITLE_VALUES)}
        </Select>

        <TextInput
          id="firstName"
          type="text"
          labelText="Vorname*"
          onChange={handleChange}
          size="sm"
          defaultValue={basicInformationFormState.firstName}
          invalid={showInvalidFieldsSave && !basicInformationFormState.firstName}
          invalidText="Bitte geben Sie einen Vornamen ein."
        />

        <TextInput
          id="lastName"
          type="text"
          labelText="Name*"
          onChange={handleChange}
          size="sm"
          defaultValue={basicInformationFormState.lastName}
          invalid={showInvalidFieldsSave && !basicInformationFormState.lastName}
          invalidText="Bitte geben Sie einen Nachnamen ein."
        />

        <TextInput
          id="birthName"
          type="text"
          labelText="Geburtsname"
          onChange={handleChange}
          size="sm"
          defaultValue={basicInformationFormState.birthName}
        />

        <DatePicker
          datePickerType="single"
          dateFormat="d.m.Y"
          onChange={(e) => handleDateChange(e, "birthday")}
        >
          <DatePickerInput
            placeholder="tt.mm.jjjj"
            labelText="Geburtsdatum*"
            id="birthday"
            size="sm"
            onChange={handleChange}
            defaultValue={basicInformationFormState.birthday}
            invalid={!birthdayIsValid(formState, showInvalidFieldsStartBot)}
            invalidText="Bitte geben Sie das Datum im validen Format ein."
          />
        </DatePicker>

        <TextInput
          id="placeOfBirth"
          type="text"
          labelText="Geburtsort*"
          onChange={handleChange}
          size="sm"
          defaultValue={basicInformationFormState.placeOfBirth}
          invalid={!basicInformationFormState.placeOfBirth && showInvalidFieldsStartBot}
          invalidText="Bitte geben Sie Geburtsort ein."
        />

        {/* TODO: in design it should be RadioButton component, but it doesn't support combination of vertical and horisontal spans. */}
        <Select
          id="nationality"
          labelText="Nationalität*"
          defaultValue={basicInformationFormState.nationality}
          size="sm"
          onChange={handleChange}
          invalid={!nationalityIsValid(formState, showInvalidFieldsStartBot)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem hidden value={""} text="Bitte auswählen" />
          {generateSelectItems(NATIONALITY_VALUES)}
        </Select>

        <RadioButtonGroup
          className={
            showInvalidFieldsSave && !basicInformationFormState.sex
              ? `border-2 border-solid border-red-600`
              : ""
          }
          legendText="Geschlecht*"
          name="sex"
          onChange={(e) => handleOtherChanges(e, "sex")}
          defaultSelected={basicInformationFormState.sex}
        >
          {generateRadioButtons(SEX_VALUES)}
        </RadioButtonGroup>

        <TextInput
          id="iban"
          type="text"
          labelText="IBAN*"
          onChange={handleChange}
          size="sm"
          defaultValue={basicInformationFormState.iban}
          invalid={!ibanBasicPersonalInformationIsValid(formState, showInvalidFieldsStartBot)}
          invalidText="Bitte geben Sie eine valide IBAN ein."
        />

        <Select
          id="familyStatus"
          labelText="Familienstand"
          defaultValue={basicInformationFormState.familyStatus}
          size="sm"
          onChange={handleChange}
          invalid={!familyStatusSinceInValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(FAMILY_STATUS_VALUES)}
        </Select>

        <Select
          id="secondaryEducation"
          labelText="Schulbildung"
          defaultValue={basicInformationFormState.secondaryEducation}
          size="sm"
          onChange={handleChange}
          invalid={!secondaryEducationIsValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(SECONDARY_EDUCATION_VALUES)}
        </Select>

        <DatePicker
          datePickerType="single"
          dateFormat="d.m.Y"
          size="sm"
          onChange={(e) => handleDateChange(e, "familyStatusSince")}
        >
          <DatePickerInput
            placeholder="tt.mm.jjjj"
            labelText="seit:"
            id="familyStatusSince"
            size="sm"
            onChange={handleChange}
            defaultValue={basicInformationFormState.familyStatusSince}
            disabled={familyStatusSinceIsDisabled}
            invalid={!familyStatusSinceInValid(formState)}
            invalidText="Bitte geben Sie das Datum im validen Format ein."
          />
        </DatePicker>

        <Select
          id="professionalEducation"
          labelText="Berufsausbildung"
          defaultValue={basicInformationFormState.professionalEducation}
          size="sm"
          onChange={handleChange}
          invalid={!professionalEducationIsValid(formState)}
          invalidText="Wählen Sie eine Option aus der Liste"
        >
          <SelectItem value={""} text="Bitte auswählen" />
          {generateSelectItems(PROFESSIONAL_EDUCATION_VALUES)}
        </Select>

        <div className="flex flex-row justify-between w-full">
          <Toggle
            labelText="Behinderung (wenn ja, bitte Nachweis hinzufügen)"
            labelA="Nein"
            labelB="Ja"
            id="disability"
            size="sm"
            onToggle={(e) => handleOtherChanges(e, "disability")}
            defaultToggled={basicInformationFormState.disability}
          />
          <IconButton
            className="mr-6 mt-[-8px]"
            size="sm"
            align="top"
            kind="ghost"
            label="Upload"
          >
            <Upload className="fill-gray-500"/>
          </IconButton>
        </div>
      </div>
    </div>
  );
}
