import path from "path";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../atoms/useUser";

interface UserContextProps {
  children: React.ReactNode;
}

const tryGetUserIdFromCookie = () => {
  //try get user from cookie string
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  let userId = "";
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("user_id")) {
      userId = cookie.split("=")[1];
      return userId;
    }
  }
  return null;
};

export const UserContext = ({ children }: UserContextProps) => {
  const [user, setUser] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (!user) {
      const userId = tryGetUserIdFromCookie();

      if (userId) {
        //set user
        setUser(userId);
      } else {
        //navigate to login page
        navigate("/");
      }
    }
  }, [user, path]);

  return <>{children}</>;
};
