import React, { useEffect, useState } from "react";
import { TextInput, NumberInput, Toggle } from "@carbon/react";
import { useFormState } from "../../atoms/useFormState";

export default function SalaryForm() {
  const [formState, setFormState] = useFormState();
  const [flatTaxEmployerCoverageIsDisabled, setFlatTaxEmployerCoverageIsDisabled] =
    useState<boolean>(true);
  const [hourlySalaryIsDisabled, setHourlySalaryIsDisabled] = useState<boolean>(true);
  const salaryData = formState.organisationalInformation.salaryData;

  const handleChange = (e) => {
    changeFormStateProperty(e.target.id, e.target.value);
  };

  const handleOtherChanges = (e, key) => {
    changeFormStateProperty(key, e);
  };

  const changeFormStateProperty = (propertyName: string, propertyValue: any) => {
    setFormState({
      ...formState,
      organisationalInformation: {
        ...formState.organisationalInformation,
        salaryData: {
          ...formState.organisationalInformation.salaryData,
          [propertyName]: propertyValue,
        },
      },
    });
  };

  // Resetting Form Values:
  // Wrapped in useEffect to avoid update errors from parent component

  useEffect(() => {
    setFlatTaxEmployerCoverageIsDisabled(getFlatTaxEmployerCoverageIsDisabled());
    setHourlySalaryIsDisabled(getHourlySalaryIsDisabled());
  }, [salaryData]);

  const getFlatTaxEmployerCoverageIsDisabled = (): boolean => {
    if (!salaryData.flatTaxGfbKfb) {
      resetProperty("flatTaxEmployerCoverage");
      return true;
    }
    return false;
  };

  const getHourlySalaryIsDisabled = (): boolean => {
    if (
      !formState.organisationalInformation.organisationSpecificInformation.employeeCircle?.includes(
        "(SL)"
      )
    ) {
      resetProperty("flatTaxEmployerCoverage");
      return true;
    }
    return false;
  };

  const resetProperty = (property: string) => {
    switch (typeof salaryData[property]) {
      case "boolean": {
        if (salaryData[property] !== false) {
          changeFormStateProperty(property, false);
        }
        break;
      }
      case "number": {
        if (salaryData[property] !== 0) {
          changeFormStateProperty(property, 0);
        }
        break;
      }
      default: {
        if (salaryData[property] !== "") {
          changeFormStateProperty(property, "");
        }
        break;
      }
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 w-full mt-4 pl-8 pr-8 pb-4">
      <TextInput
        name="group"
        id="group"
        type="text"
        labelText="Gruppe"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.group}
      />

      <TextInput
        name="level"
        id="level"
        type="text"
        labelText="Stufe"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.level}
      />

      <TextInput
        name="allowance1"
        id="allowance1"
        type="text"
        labelText="Zulage (Bezeichnung)"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.allowance1}
      />

      <TextInput
        name="allowanceAmount1"
        id="allowanceAmount1"
        type="text"
        labelText="Betrag"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.allowanceAmount1}
      />

      <TextInput
        name="allowance2"
        id="allowance2"
        type="text"
        labelText="Zulage (Bezeichnung)"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.allowance2}
      />

      <TextInput
        name="allowanceAmount2"
        id="allowanceAmount2"
        type="text"
        labelText="Betrag"
        size="sm"
        onChange={handleChange}
        defaultValue={salaryData.allowanceAmount2}
      />

      <NumberInput
        className="col-span-2"
        id="hourlySalary"
        min={0}
        value={salaryData.hourlySalary ? salaryData.hourlySalary : 0}
        label="Stundenlohn (Betrag in €)"
        invalidText="Bitte Stundenlohn eingeben"
        size="sm"
        onChange={handleChange}
        disabled={hourlySalaryIsDisabled}
      />

      <div className="grid grid-cols-5 gap-4 col-span-2">
        <Toggle
          className="col-span-2"
          labelText="Pauschalsteuer GfB / KfB"
          labelA="Nein"
          labelB="Ja"
          id="flatTaxGfbKfb"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "flatTaxGfbKfb")}
          defaultToggled={salaryData.flatTaxGfbKfb}
        />

        <Toggle
          className="col-span-3"
          labelText="Wenn ja: Abwälzung auf Arbeitnehmer?"
          labelA="Nein"
          labelB="Ja"
          id="flatTaxEmployerCoverage"
          size="sm"
          onToggle={(e) => handleOtherChanges(e, "flatTaxEmployerCoverage")}
          defaultToggled={salaryData.flatTaxEmployerCoverage}
          disabled={flatTaxEmployerCoverageIsDisabled}
        />
      </div>
    </div>
  );
}
