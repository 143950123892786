import React from "react";
import { Accordion, AccordionItem } from "@carbon/react";
import BasicInformationForm from "./BasicInformationForm";
import AddressForm from "./AddressForm";
import TaxDataForm from "./TaxDataForm";
import SocialInsuranceDataForm from "./SocialInsuranceDataForm";

export default function PersonalInformation() {
  return (
    <div className="mb-12">
      <Accordion className="pt-4" >
        <AccordionItem open={true} title="Persönliche Informationen">
          <BasicInformationForm />
        </AccordionItem>
        <AccordionItem title="Anschrift">
          <AddressForm />
        </AccordionItem>
        <div className="grid grid-cols-2 w-full">
          <div>
            <AccordionItem title="Steuerliche Informationen">
              <TaxDataForm />
            </AccordionItem>
          </div>
          <div>
            <AccordionItem title="Sozialversicherungsdaten">
              <SocialInsuranceDataForm />
            </AccordionItem>
          </div>
        </div>
      </Accordion>
    </div>
  );
}
