import { getEmployeeData } from "../../api/getEmployeeData";
import { putEmployeeData } from "../../api/putEmployeeData";
import { ChatMessageProps } from "./ChatMessage";
import { wait } from "./wait";

export class MessageHandler {
  private locked: boolean = false;
  private canceled: boolean = false;
  private messages: ChatMessageProps[] = [];
  private chatOpen: boolean = true;

  constructor(
    private userId: string,
    private userName: string,
    private onMessageUpdate: (messages: ChatMessageProps[]) => void,
    private onNewMessage?: () => void
  ) {}

  private async getChatMessages() {
    const data = await getEmployeeData(this.userId);
    return data.chat_messages;
  }

  private async updateChatMessages(messages = this.messages, setRead: boolean = false) {
    if (setRead) {
      messages = messages.map((message) => {
        if (message.sender !== this.userName) {
          return { ...message, read: true };
        } else {
          return message;
        }
      });
    }
    this.messages = messages;
    this.onMessageUpdate(messages);
    await putEmployeeData(JSON.stringify({ chat_messages: messages }), this.userId);
  }

  getMessages() {
    return this.messages;
  }

  async setChatOpen(open: boolean) {
    this.chatOpen = open;
    if (open) {
      while (this.locked) {
        await wait(200);
      }
      this.locked = true;
      await this.updateChatMessages(this.messages, true);
      this.locked = false;
    }
  }

  async pollChatMessages() {
    while (!this.canceled) {
      while (this.locked) {
        await wait(200);
      }
      this.locked = true;
      const messages = await this.getChatMessages();

      if (messages) {

        // check if number of red Messages has changed
        const readMessages = messages.filter((message) => message.read);
        const oldReadMessages = this.messages.filter((message) => message.read);
  

        if (messages.length !== this.messages.length || readMessages.length !== oldReadMessages.length) {
          await this.updateChatMessages(messages, this.chatOpen);
        }

      }

      this.locked = false;
      await wait(5000);
    }
  }

  async sendMessage(message: string) {
    while (this.locked) {
      await wait(200);
    }
    this.locked = true;

    const messages = await this.getChatMessages();

    const newMessage: ChatMessageProps = {
      sender: this.userName,
      message: message,
      read: false,
      timestamp: new Date().toISOString(),
    };

    if (messages) messages.push(newMessage);
    await this.updateChatMessages(messages);
    this.locked = false;
  }

  async cancel() {
    this.canceled = true;
  }
}
