import { useEffect, useState } from "react";
import { useResetRecoilState } from "recoil";
import { getEmployeeData } from "../api/getEmployeeData";
import { defaultFormState, useFormState } from "../atoms/useFormState";
import { EmployeeData } from "../types/OnboardingProcess";

export const useEmployeeData = (id: string | undefined) => {
  const [employeeData, setEmployeeData] = useState<EmployeeData>();
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useFormState();

  useEffect(() => {
    const fetchEmployeeData = async (id: string) => {
      const employeeData = await getEmployeeData(id);
      const unifiedFormState = getUnifiedFormState(employeeData);
      setEmployeeData({ ...employeeData, form_data: unifiedFormState });
      setFormState(unifiedFormState);
      setIsLoading(false);
    };

    const getUnifiedFormState = (employeeData: EmployeeData) => {
      return {
        personalInformation: {
          basicPersonalInformation: {
            ...defaultFormState.personalInformation.basicPersonalInformation,
            ...employeeData.form_data.personalInformation.basicPersonalInformation,
          },
          address: {
            ...defaultFormState.personalInformation.address,
            ...employeeData.form_data.personalInformation.address,
          },
          taxData: {
            ...defaultFormState.personalInformation.taxData,
            ...employeeData.form_data.personalInformation.taxData,
          },
          socialInsuranceData: {
            ...defaultFormState.personalInformation.socialInsuranceData,
            ...employeeData.form_data.personalInformation.socialInsuranceData,
          },
        },
        organisationalInformation: {
          organisationSpecificInformation: {
            ...defaultFormState.organisationalInformation.organisationSpecificInformation,
            ...employeeData.form_data.organisationalInformation.organisationSpecificInformation,
          },
          salaryData: {
            ...defaultFormState.organisationalInformation.salaryData,
            ...employeeData.form_data.organisationalInformation.salaryData,
          },
          capitalFormationData: {
            ...defaultFormState.organisationalInformation.capitalFormationData,
            ...employeeData.form_data.organisationalInformation.capitalFormationData,
          },
        },
      };
    };

    if (id && id != "new") {
      fetchEmployeeData(id);
    } else if (id === "new") {
      setIsLoading(false);
      setFormState(defaultFormState);
    }
  }, [id]);

  return { isLoading, employeeData };
};
