import { EmployeeData } from "../types/OnboardingProcess";

export const postEmployeeData = async (employeeData) => {
  //turn employeedata into a string
  
  const requestBody = JSON.stringify(employeeData);
  const requestOptions = {
    method: "POST",
    body: requestBody,
    headers: { "Content-Type": "application/json" },
  };
  const response = await fetch(`/api/employees`, requestOptions);
  const data = await response.json();
  return data;
};
